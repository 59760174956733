import get from 'lodash-es/get'
import Vue from 'vue'
import { useExtensions } from '/~/composables/extensions'

Vue.mixin({
  computed: {
    /*
     * Get extension class based on $route.meta.extName value
     */
    $ext() {
      const extName = get(this.$route, 'meta.extName', '')
      const { extensions } = useExtensions()
      const extension =
        extName && extensions.instances.find((ext: any) => ext.name === extName)

      return extension || {}
    },
  },
})
